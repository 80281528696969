export default function () {
  $('#newsletter').on('submit', function (event) {
    event.preventDefault();

    const $form     = $(this);
    const $succcess = $('#formSuccess');
    const url       = $form.attr('action');

    function startLoading () {
      $form.addClass('loading');
    }

    function stopLoading () {
      $form.removeClass('loading');
    }

    startLoading();

    $.ajax({
      url,
      type: 'POST',
      data: $form.serialize(),

      success: function (data) {
        const success = data.body.success;
        stopLoading();
        if (success) {
          $form.removeClass('error');
          $form.hide();
          $succcess.show();
        } else {
          $form.addClass('error');
          console.error(data.body.message);
        }
      },

      error: function (error) {
        stopLoading();
        $form.addClass('error');
        console.error(error);
      },
    });
  });
}
