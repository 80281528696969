const lightbox = require('lightbox2');

export default function () {
    // bit hacky but this lightbox doesn't provide programmatic opening
    $('.gallery').on('click', 'a.count', event => {
        event.preventDefault();
        $(event.delegateTarget)
            .find('.item')
            .first()
            .click();
    });

    // https://lokeshdhakar.com/projects/lightbox2/#options
    lightbox.option({
        alwaysShowNavOnTouchDevices: true,
        albumLabel:                  '%1/%2',
    });
}
