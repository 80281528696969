export default function () {
  const $scrollables = $('.scrollable');
  const $left        = $scrollables.find('.before');
  const $right       = $scrollables.find('.after');

  $scrollables.each(function () {
    const $scrollable = $(this);
    const $scroll     = $scrollable.find('.scroll');
    const $left       = $scrollable.find('.before');
    const $right      = $scrollable.find('.after');
    const initScroll  = $scrollable.scrollLeft();

    if (!initScroll) {
      $left.hide();
    }
    if ($scroll.width() <= $scrollable.width()) {
      $right.hide();
    }
  });

  $left.click((event) => {
    event.preventDefault();
    const $scrollable = $(event.currentTarget).parent();
    const $scroll     = $scrollable.find('.scroll');
    const scrollTo    = $scrollable.scrollLeft() - Math.floor($scrollable.width() / 2);

    $scrollable.scrollLeft(scrollTo);

    if (!$scrollable.scrollLeft()) {
      $scrollable.find('.before').hide();
    }
    if ((scrollTo + $scrollable.width()) < $scroll.width()) {
      $scrollable.find('.after').show();
    }

  });

  $right.click((event) => {
    event.preventDefault();
    const $scrollable = $(event.currentTarget).parent();
    const $scroll     = $scrollable.find('.scroll');
    const scrollTo    = $scrollable.scrollLeft() + Math.floor($scrollable.width() / 2);

    $scrollable.scrollLeft(scrollTo);

    if ($scrollable.scrollLeft()) {
      $scrollable.find('.before').show();
    }
    if ((scrollTo + $scrollable.width()) > $scroll.width()) {
      $scrollable.find('.after').hide();
    }
  });
}
