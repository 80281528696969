import './bootstrap';
import Calendar   from './components/Calendar';
import CookieNag  from './components/CookieNag';
import Dropdown   from './components/Dropdown';
import Gallery    from './components/Gallery';
import Handicap   from './components/Handicap';
import LinkCards  from './components/LinkCards';
import Lozad      from './components/Lozad';
import MainMenu   from './components/MainMenu';
import './components/map';
import Modal      from './components/Modal';
import Newsletter from './components/Newsletter';
import Scrollable from './components/Scrollable';
import ScrollTo   from './components/ScrollTo';
import SearchForm from './components/SearchForm';
import Sticky     from './components/Sticky';
import Video      from './components/Video';

$(document).ready(function () {
  Sticky();
  MainMenu();
  Calendar();
  Dropdown();
  SearchForm();
  Scrollable();
  ScrollTo();
  LinkCards();
  Gallery();
  Video();
  Modal();
  CookieNag();
  Newsletter();
  Lozad();
  Handicap();
});
