// Lazy loading utility
window.lozad = require('./lozad');

// jQuery
window.$ = window.jQuery = require('jquery');

// Marker Clusterer
window.MarkerClusterer = require('@google/markerclusterer');

// Semantic UI utils
require('fomantic-ui-less/definitions/behaviors/visibility');
require('fomantic-ui-less/definitions/behaviors/form');
require('fomantic-ui-less/definitions/modules/calendar');
require('fomantic-ui-less/definitions/modules/checkbox');
require('fomantic-ui-less/definitions/modules/dimmer');
require('fomantic-ui-less/definitions/modules/dropdown');
require('fomantic-ui-less/definitions/modules/modal');
require('fomantic-ui-less/definitions/modules/sticky');
require('fomantic-ui-less/definitions/modules/popup');
require('fomantic-ui-less/definitions/modules/transition');
require('fomantic-ui-less/definitions/modules/sidebar');
require('fomantic-ui-less/definitions/modules/nag');
