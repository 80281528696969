import DateRange from './Calendar';

export default function () {
  $('#inquiry-form').on('submit', function (event) {
    gtag('event', 'email-send', {
      'event_category': 'send',
    });
  });

  const $modals = $('[data-modal-trigger]');

  $modals.click(function onModalClick() {
    const modal = $(this).data('modal-trigger');
    const $modal = $(`.ui.modal.${modal}`);

    $modal.modal('show');

    if (modal === 'inquiry') {
      DateRange('#inquiry-rangestart', '#inquiry-rangeend');
    }
  });
};
