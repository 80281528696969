export default function () {
  $('.ui.dropdown.tags')
    .dropdown({
      allowAdditions: true,
      clearable:      true,

      className: {
        label: 'ui secondary label',
      },
    });

  $('.ui.dropdown.languages')
    .dropdown();
}
