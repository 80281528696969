window.initMap = () => {
  const geo = new Maps(50.254711, 19.0381268);
  geo.initMap();
  for (let i = 0; i < positions.length; i++) {
    geo.addMarker(positions[i]);
  }
  if (positions.length === 1) {
    geo.showMarker();
  } else if (positions.length) {
    geo.showAllMarkers();
  }
  geo.clusterMarkers();
};

function Maps (lat, lng) {
  this.mapEl        = document.querySelector('#gmap');
  this.markers      = [];
  this.center       = { lat, lng };
  this.zoom         = 17;
  this.infoTemplate = '<div id="content"><h1 id="firstHeading" class="firstHeading">{{title}}</h1></div>';
  this.infoWin      = new google.maps.InfoWindow();
  this.icon         = {
    // TODO Use when implementing packets
    // packet:  {
    //   url:        GLOBAL.asset + 'svg/map/pointer-packet.svg',
    //   anchor:     new google.maps.Point(20, 35),
    //   scaledSize: new google.maps.Size(40, 40),
    // },
    pointer: {
      url:        GLOBAL.asset + 'svg/map/pointer.svg',
      anchor:     new google.maps.Point(20, 35),
      scaledSize: new google.maps.Size(40, 40),
    },
  };

  this.initMap = () => {
    this.map = new google.maps.Map(this.mapEl, { zoom: this.zoom, center: this.center });
  };

  this.addMarker = (data) => {
    const position          = { lat: data.lat, lng: data.lng };
    const marker            = new google.maps.Marker({
      position,
      map:   this.map,
      title: data.name,
      icon:  this.icon.pointer,
    });
    this.markers[data.slug] = marker;
    this.addInfoWindow(marker);
  };

  this.showMarker = () => {
    const markers = Object.values(this.markers);
    this.map.setZoom(this.zoom);
    this.map.setCenter(markers[0].getPosition());
  };

  this.showAllMarkers = () => {
    const bounds  = new google.maps.LatLngBounds();
    const markers = Object.values(this.markers);

    for (let i = 0; i < markers.length; i++) {
      bounds.extend(markers[i].getPosition());
    }

    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      const extendPoint1 = new google.maps.LatLng(
        bounds.getNorthEast().lat() + 0.01,
        bounds.getNorthEast().lng() + 0.01);
      const extendPoint2 = new google.maps.LatLng(
        bounds.getNorthEast().lat() - 0.01,
        bounds.getNorthEast().lng() - 0.01);
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }

    this.map.fitBounds(bounds);
  };

  this.clusterMarkers = () => {
    const clusterStyles = [
      {
        width:     37,
        height:    37,
        url:       GLOBAL.asset + 'svg/map/group.svg',
        textColor: 'white',
        textSize:  14,
      },
    ];
    new MarkerClusterer(this.map, this.markers, { styles: clusterStyles });
  };

  this.addInfoWindow = (marker) => {
    marker.addListener('click', () => {
      this.infoWin.setContent(this.infoTemplate.replace('{{title}}', marker.title));
      this.infoWin.open(this.map, marker);
    });
  };
}
