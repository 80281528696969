export default function () {
    const mainMenuHeight = 74;

    $('[data-scroll-to]').on('click', 'a', function (event) {
        event.preventDefault();
        const $target = event.currentTarget.hash.length > 1 ? $(event.currentTarget.hash) : [];
        if ($target.length) {
            const scrollTo = $target.offset().top - mainMenuHeight;
            $(document.scrollingElement).animate({
                scrollTop: scrollTo,
            }, 300);
        }
    });
}
