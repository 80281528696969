export default function () {
  $('.ui.nag')
    .nag({
      key:           'accepts-cookies',
      value:         true,
      storageMethod: 'localstorage',
      selector:      {
        close: '.button.close',
      },
    });
}
