export default function (rangeStart = '#rangestart', rangeEnd = '#rangeend') {
  const $rangestart  = $(rangeStart);
  const $rangeend    = $(rangeEnd);
  const formatter    = { date: (date) => !date ? '' : date.toLocaleDateString() };
  const $generalDate = $('.ui.calendar.general');

  $rangestart.calendar({
    type:           'date',
    endCalendar:    $rangeend,
    monthFirst:     false,
    firstDayOfWeek: 1,
    text:           window.calendarText,
    formatter,
  });

  $rangeend.calendar({
    type:           'date',
    startCalendar:  $rangestart,
    monthFirst:     false,
    firstDayOfWeek: 1,
    text:           window.calendarText,
    formatter,
  });

  $generalDate.calendar({
    type:           'date',
    monthFirst:     false,
    firstDayOfWeek: 1,
    text:           window.calendarText,
    formatter,
  });
}
