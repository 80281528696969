export default function () {
  // fix menu when passed
  $('.masthead')
    .visibility({
      once: false,
      onBottomPassed () {
        $('.popping.menu').transition('fade in');
      },
      onBottomPassedReverse () {
        $('.popping.menu').transition('fade out');
      },
    });

  // create sidebar and attach to menu open
  $('.ui.sidebar').sidebar('attach events', '.toc.item');
}
