const localStorageFacade = (() => {
  // Mocking localStorage for browsers that do not support it (fe. Safari's incognito mode)
  if (typeof (localStorage) === 'undefined') {
    localStorage = {
      /* eslint-disable no-unused-vars */
      setItem: function (key, value) {},
      getItem: function (key) { return null; },
      /* eslint-enable no-unused-vars */
    };
  }

  return localStorage;
})();

const handicapStorage = {
  setItem: (data) => localStorageFacade.setItem('handicap', data),
  getItem: () => localStorageFacade.getItem('handicap'),
};

export default function () {
  const $html             = $('html');
  const $handicapFont     = $('.handicap.handicap--font');
  const $handicapContrast = $('.handicap.handicap--contrast');
  const handicapStatus    = handicapStorage.getItem();

  if (handicapStatus) {
    $html.attr('class', handicapStatus);
  }

  $handicapFont.click((event) => {
    event.preventDefault();
    $html.toggleClass('handicap-font');
    handicapStorage.setItem($html.attr('class'));
  });

  $handicapContrast.click((event) => {
    event.preventDefault();
    $html.toggleClass('handicap-contrast');
    handicapStorage.setItem($html.attr('class'));
  });
}
