export default function () {
  const $mobileFilterButton    = $('[data-mobile-filter-button]');
  const $mobileMapRevealButton = $('[data-mobile-map-reveal]');
  const $triggerForm           = $('[data-trigger-activate]');
  const $triggers              = $triggerForm.find('[data-trigger]');

  $triggers.click(() => {
    $triggerForm.submit();
  });

  $mobileFilterButton.click((event) => {
    event.preventDefault();
    const $togglableElements = $('[data-mobile-filter-toggle]');
    $togglableElements.toggleClass('tablet only');
  });

  $mobileMapRevealButton.click((event) => {
    event.preventDefault();
    const $map = $('.gmap-container-mobile');

    $map.toggleClass('hidden');
  });
}
